import { render, staticRenderFns } from "./add_bank.vue?vue&type=template&id=74a53ad3&scoped=true&"
import script from "./add_bank.vue?vue&type=script&lang=js&"
export * from "./add_bank.vue?vue&type=script&lang=js&"
import style0 from "./add_bank.vue?vue&type=style&index=0&id=74a53ad3&prod&scoped=true&lang=scss&stype=text%2Fcss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74a53ad3",
  null
  
)

export default component.exports