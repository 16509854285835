<script>
import { add_bank } from "@/api/my";
import { bank_list } from "@/api/my";
import { kf_bank } from "@/api/my";
export default {
    name:'Withdraw_SetBankCard',
    components: {
        Header: () => import('components/base/Header'),

        'mt-header':app.Mint.Header,
        'mt-button':app.Mint.Button,
        'mt-actionsheet':app.Mint.Actionsheet,
    },
    data() {
        return {
            username: "",
            sheetVisible: false,
            bank_num: "",
            subbranch: "",
            pay_name: "",
            bank_name: "",
            card_province:"",
            card_city:"",
            password: "",
            option: "",
            bank_id: "",
            banklist: {},
            banklistshow: [
                { id: null, method: this.getresult1, name: getWord(['select','bank']), type: "" }
            ],

            header: {
                mainTitle: getWord(['bind','bankcard']),
            },
            currentType:null,
            tab:[
                {
                    title:getWord(['china', 'bank']),
                    active:true,
                    type:1,
                },
                {
                    title:getWord(['outside_of_china', 'bank']),
                    active:false,
                    type:2,
                },
            ],
            currency:localStorage.getItem('currency'),
        };
    },
    methods: {
        checkRequired(key,checkExist){
            var _=null;

            if (checkExist) {
                if (self.localStorage.getItem(key) && self.localStorage.getItem(key)!=="null") {
                    return true;
                }else {
                    return false;
                }
            }
        
            if (self.localStorage.getItem(key)) {
                self.localStorage.getItem(key)==='true'
                    ?_=true
                    :_=false;
            }else {
                _=false;
            }
            return _; 
        },
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push("/home");
            } else {
                this.$router.go(-1);
            }
        },
        get_game: function(e) {
            console.log(e);
            this.sheetVisible = true;
        },
        getresult1: function(val, inx) {
             console.log(val, inx);
            this.banklist = val;
        },
        addbank() {
            var bank_num_reg = /^[0-9]*$/;
            var pay_name_reg = /\d+/; // /^[\u4E00-\u9FA5]+$/;
            var password_reg = /^[0-9]{6,8}$/;
            if (!this.bank_num.match(bank_num_reg)) {
                app.Mint.Toast(getWord('username_rules12'));
            } else if (!this.pay_name || pay_name_reg.test(this.pay_name)) {
                app.Mint.Toast(getWord(['real_name','username_rules14']));
            } else if (!this.password.match(password_reg)) {
                app.Mint.Toast(getWord('password_rules17',{
                        '$1':'6',
                        '$2':'8',
                    }));
            } else {
                console.log( this.currentType,this.banklist["name"],this.bank_name);
                add_bank(
                    this.bank_num,
                    this.subbranch,
                    this.pay_name,
                    this.currentType===2?this.banklist["name"]:this.bank_name,
                    this.password
                ).then(result => {
                    if (result.data.code == "SUCCESS") {
                        app.Mint.Toast(getWord(['add_bankcard2', 'success']));
                        var url = "/my/my_bank/no_bank";
                        this.$router.push({ path: url });
                    } else {
                        app.Mint.Toast(result.data.msg);
                    }
                });
            }
        },
        bank_list() {
            kf_bank().then(result => {
                if (result.data.code == "SUCCESS" && result.data.result) {
                    for (var i = 0; i < result.data.result.length; i++) {
                        this.banklistshow[i] = result.data.result[i];
                        this.banklistshow[i].name = result.data.result[i].bank;
                        this.banklistshow[i].method = this.getresult1;
                    }
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });
        },
        tabSwitch(item){
            this.tab.forEach(item=>{
                item.active=false;
            })
            item.active=true;
            this.currentType=item.type;
        },
    },
    mounted() {
        this.bank_list();
        if(_TEMPLATE!=='template-3') {
            this.currentType=(this.currency&&this.currency==='cny')
                                    ?1
                                    :2
        }else {
            this.currentType=this.tab.filter(item=>{
                return item.active===true;
            })[0].type;
        }
    },
};
</script>
<template>
    <div id="add-bank" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <div class="myposition"></div>
            <div class="main">
                <Header
                    :mainTitle="header.mainTitle"
                    :backButton=false
                />
                <div class="total">
                    <span class="main_span">{{ getWord(['bank', 'cardnumber']) }}</span>
                    <input @blur="_blured($event)" type="number" class="mian_input" :placeholder="getWord('fill_bankcard2')" v-model="bank_num">
                </div>
                <div class="total" style="margin-top: 0.3rem;" v-if="!currency || currency==='cny'">
                    <span class="main_span">{{ getWord('bank_name') }}</span>
                    <div class="main_header_select" @click="get_game">
                        <img class="main_header_select_img" src="@@/assets/images2/user/sj.png" alt>
                        {{banklist['bank']}}
                    </div>
                </div>
                <div class="total" style="margin-top: 0.3rem;" v-else>
                    <span class="main_span">{{ getWord('bank_name') }}</span>
                    <input @blur="_blured($event)" type="text" class="mian_input" :placeholder="getWord('fill_bank_name')" v-model="bank_name">
                </div>

                <div class="total" style="margin-top: 0.3rem;">
                    <span class="main_span">{{ getWord('bank_branch_name') }}</span>
                    <input @blur="_blured($event)" type="text" class="mian_input" :placeholder="getWord('fill_bank')" v-model="subbranch">
                </div>

                <div class="total" style="margin-top: 0.3rem;">
                    <span class="main_span">{{ getWord('real_name') }}</span>
                    <input @blur="_blured($event)" type="text" class="mian_input" :placeholder="getWord('fill_username3')" v-model="pay_name">
                </div>

                <div class="total" style="margin-top: 0.3rem;">
                    <span class="main_span">{{ getWord('fill_withdraw_password4') }}</span>
                    <input @blur="_blured($event)" type="text" class="mian_input" :placeholder="getWord('password_rules17',{
                        '$1':'6',
                        '$2':'8',
                    })" v-model="password">
                </div>

                <mt-button class="mt_button" type="primary" @click="addbank()">{{ getWord('confirm2') }}</mt-button>
            </div>
            
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
        
            <div class="tabbed round">
                <ul>
                    <li @click="tabSwitch(item)" v-for="item in tab" :class="{ 'active':item.active }">
                        <span>{{ item.title }}</span>
                    </li>
                </ul>
            </div>

            <div class="docker">
            
            <div class="form-body" >
                <div class="fieldset clearfix">
                    <span class="required">{{ getWord(['bank', 'cardnumber']) }}</span>
                    <input @blur="_blured($event)" type="text" :placeholder="getWord('fill_bankcard2')" v-model="bank_num">
                </div>
                <div class="fieldset clearfix select" @click="get_game" :class="{'selected':banklist['bank']}" v-if="currentType===1">
                    <span class="required">{{ getWord('bank_name') }}</span>
                    <input disabled="true" text="text" v-model="banklist['bank']" :placeholder="getWord('fill_bank')" />
                    <i class="iconfont icon-back"></i>
                </div>
                <div class="fieldset clearfix" v-if="currentType===2">
                    <span class="required">{{ getWord('bank_name') }}</span>
                    <input @blur="_blured($event)" text="text" :placeholder="getWord('fill_bank_name')" v-model="bank_name" />
                </div>
                <div class="fieldset clearfix">
                        <span class="required">{{ getWord('bank_branch_name') }}</span>
                    <input @blur="_blured($event)" :placeholder="getWord(['select','bank_branch_name'])" v-model="subbranch" />
                </div>
                <div class="fieldset clearfix">
                        <span class="required">{{ getWord('real_name') }}</span>
                    <input @blur="_blured($event)" :placeholder="getWord('fill_username3')" v-model="pay_name" />
                </div>
                <div class="fieldset clearfix">
                        <span class="required">{{ getWord('withdraw_password') }}</span>
                    <input @blur="_blured($event)" type="text" :placeholder="getWord('password_rules17',{
                        '$1':'6',
                        '$2':'8',
                    })" v-model="password" />
                </div>
            </div>
                
            <div class="buttons">
                <a class="login" @click="addbank()">{{ getWord('confirm2') }}</a>
            </div>
            </div>
        </template>
        <mt-actionsheet :actions="banklistshow" v-model="sheetVisible" class="footer"></mt-actionsheet>
    </div>
</template>

<style scoped lang='scss' stype="text/css">
#add-bank {

    &.template-3 {
        background-color: #f2f2f2;
        height: 100%;

        .form-body {
            padding: 0 .25rem;
            background-color: #ffffff;

            .fieldset {
                padding: .2rem 0;
                border-top: 1px solid #eeeeee;
                display: flex;
                align-items: center;

                &.select {

                    input {
                        opacity: 1;
                    }

                    &.selected {

                        input {
                            -webkit-text-fill-color: #333333;
                            opacity: 1;
                            color: #333333;
                        }
                    }
                }

                &:first-child {
                    border-top: 0;
                }

                span {
                    flex-basis: 1.5rem;
                    font-size: .3rem;
                    color: #333333;

                    &:before {
                        display: none;
                        content:'*';
                        color: #EC2829;
                }
                }

                input {
                    border: 0;
                    box-sizing: border-box;
                    padding: 0 .25rem;
                    font-size: .3rem;
                    height: .48rem;
                    vertical-align: middle;
                    width: 100%;

                    &:disabled {
                        background-color: transparent;
                    }

                    &:focus {
                        background-color: #FAFFBD;
                    }

                    &::placeholder {
                        color: #B0B0B0;
                    }
                }

                .iconfont {
                    font-size: .4rem;
                    text-align: center;
                    display: inline-block;
                    color: #B0B0B0;
                    vertical-align: middle;

                    &.icon-back {
                        position: absolute;
                        transform: rotateZ(180deg);
                        right: .15rem;
                        opacity: .4;
                    }
                }
            }
        }

        .buttons {
            padding: 0 .5rem;
            margin: .5rem 0;

            :first-child {
                font-size: .34rem;
                background-color: #EC2829;
                color: #ffffff;
            }

            a {
                display: block;
                font-size: .34rem;
                color: #EC2829;
                background-color: #ffffff;
                border-radius: .1rem;
                margin-top: .25rem;
                text-align: center;
                padding: .2rem 0;
            }
        }
    }

    .docker {
        margin: .25rem;
        box-shadow: 0px 3px 4px 1px rgba(0,0,0,.2);
        background-color: #ffffff;
        padding-bottom:.25rem;
        border-radius: 0 0 .1rem .1rem;
        margin-top: 0;
        position: relative;
        z-index: 2;
}

    /* Tabbed Styles */
    .tabbed {
        transition: border 250ms ease;
        margin-top: .25rem;
        position: relative;
        z-index: 1;
    }
    .tabbed ul {        
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        display: flex;
        margin: 0 .25rem;
    }
    .tabbed ul * {
        margin: 0px;
        padding: 0px;
    }
    .tabbed ul li {
        text-align: center;
        display: block;
        padding: .33rem 24px .15rem;
        background-color: #eee;
        margin-right: 6px;
        z-index: 2;
        position: relative;
        cursor: pointer;
        color: #999;
        position:relative;
        top:.1rem;

        text-transform: uppercase;
        font: 400 13px/20px roboto, "Open Sans", Helvetica, sans-serif;

        transition: all 250ms ease;

        &:last-child {
            margin-right:0;
        }

        span {
            position: relative;
            width: 100%;
            display: block;
            top: -.15rem;

            &:before {
                content:'';
                background-color: #EC2829;
                width: .4rem;
                height: .04rem;
                border-radius: 1rem;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
            }
        }
    }
    .tabbed ul li.active:before,
    .tabbed ul li.active:after {
        display: block;
        content: " ";
        position: absolute;
        top: 0;
        height: 100%;
        width: 14px;    
        background-color: #FFF;
        transition: all 250ms ease;
    }
    .tabbed ul li:before {
        right: -10px;
        transform: skew(10deg, 0deg);
        box-shadow: rgba(0,0,0,.1) 3px 2px 5px, inset rgba(255,255,255,.09) -1px 0;
    }
    .tabbed ul li:after {
        left: -10px;
        transform: skew(-10deg, 0deg);
        box-shadow: rgba(0,0,0,.1) -3px 2px 5px, inset rgba(255,255,255,.09) 1px 0;
    }
    .tabbed ul li:hover,
    .tabbed ul li:hover:before,
    .tabbed ul li:hover:after {
        background-color: #F4F7F9;
        color: #444;
    }
    .tabbed ul li.active {
        z-index: 3;
        top:0;
        font-weight: 600;

        span {
            top:-.066rem;
        }
    }
    .tabbed ul li.active,
    .tabbed ul li.active:before,
    .tabbed ul li.active:after {
        background-color: #fff;
        color: #333;
    }

    /* Round Tabs */
    .tabbed.round ul li {
        width: 50%;
        border-radius: 3px 3px 0 0;
        box-shadow: 0 0 6px 0 rgba(0,0,0,.3);

        &:last-child {

            &.active {

                &:before {
                    content:none;
                }

                &:after {
                    content:'';
                }
            }
        }
    }
    .tabbed.round ul li:before {
        border-radius: 0 0 0 0;
        box-shadow: 4px -2px 8px -2px rgba(0, 0, 0, 0.3);
    }
    .tabbed.round ul li:after {
        content: none;
        border-radius: 8px 0 0 0;
    }
}
.myposition {
    background-color: rgba(239, 239, 239, 1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}
.total {
    float: left;
    margin-top: 0.55rem;
    width: 100%;
    outline: none;
}
.main_span {
    float: left;
    height: 0.68rem;
    width: 30%;
    font-size: 0.3rem;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    text-align: center;
    line-height: 0.68rem;
    outline: none;
}
.mian_input {
    float: right;
    height: 0.68rem;
    width: 60%;
    border: 1px solid rgba(187, 187, 187, 1);
    border-radius: 0.1rem;
    margin-right: 0.4rem;
    box-shadow: 0 0 0.05rem #ccc;
    padding-left: 0.2rem;
    outline: none;
    font-size: 0.24rem;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    outline: none;
}
.mt_button {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.5rem;
    margin-left: 5%;
}
.main_header_select_img {
    float: right;
    width: 0.15rem;
    height: 0.15rem;
    padding: 0.3rem 0.2rem;
}
.main_header_select {
    float: left;
    width: 60%;
    height: 0.68rem;
    background-color: white;
    border: 1px solid rgba(187, 187, 187, 1);
    font-size: 0.24rem;
    line-height: 0.7rem;
    color: rgba(51, 51, 51, 1);
    outline: none;
    padding-left: 0.2rem;
    margin: 0;
    border-radius: 0.1rem;
    margin-left: 0.1rem;
}
.footer{
    float: left;
    height:8rem;
    overflow-x: hidden;
    overflow-y: scroll;
}
</style>


